import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { actionSetShopBasket } from "../redux/actions/shop_basket_actions";
import { store } from "./../redux/Store";
import { toast } from "react-toastify";
import { numberWithCommas } from "../Utils";
import { successToast } from "../Utils/TostiUtil";
import { useSelector } from "react-redux";
import SpecTable from "./../Components/Common/ProductComp/SpecTable";
import ImageWithThmbView from "./../Components/Common/ProductComp/ImageWithThmbView";
import OrderSection from "./../Components/Common/ProductComp/OrderSection";
import { Helmet } from "react-helmet";

export default function Product(props) {
  const basket = useSelector((state) => state.shopBasketReducer.basket);
  const { items } = props.params.state ? props.params.state : {};
  const { images, category, id } = items;
  const [orderDaysOrHours, setOrderDaysOrHours] = useState(0);
  const [deliveryReq, setDeliveryReq] = useState(false);
  const [total, setTotal] = useState(0);
  const [isDaily, setIsDaily] = useState(items.price.daily ? true : false);

  useEffect(() => {
    const getTotal = isDaily
      ? items.price.nextDays && orderDaysOrHours > 1
        ? items.price.daily +
        items.price.nextDays * (orderDaysOrHours - 1) +
        (deliveryReq ? items.price.delivery : 0)
        : items.price.daily * orderDaysOrHours +
        (deliveryReq ? items.price.delivery : 0)
      : items.price.hourly + items.price.nextHours * (orderDaysOrHours - 1) +
      (deliveryReq ? items.price.delivery : 0);
    setTotal(getTotal);
  }, [orderDaysOrHours, deliveryReq]);

  const _checkOrderedBefore = async (basket) => {
    const orderedBefore = basket.find((basketItem) => {
      return basketItem.items.id === id;
    });
    if (orderedBefore) {
      const { deliveryReq, isDaily, orderDaysOrHours } = orderedBefore;
      return { deliveryReq, isDaily, orderDaysOrHours };
    }
    return undefined;
  };

  useEffect(() => {
    basket.length > 0
      ? _checkOrderedBefore(basket).then((res) => {
        if (res) {
          console.log(res, "res");
          const { deliveryReq, isDaily, orderDaysOrHours } = res;
          setDeliveryReq(deliveryReq);
          setIsDaily(isDaily);
          setOrderDaysOrHours(orderDaysOrHours);
        }
      })
      : setTotal(0);
    setOrderDaysOrHours(0);
  }, [basket, id]);

  const _addToBasket = () => {
    store.dispatch(
      actionSetShopBasket({
        productName: items.name,
        pictureImage: images[0].source,
        orderDaysOrHours: orderDaysOrHours,
        deliveryReq: deliveryReq,
        price: total,
        isDaily: isDaily,
        items: items,
      })
    );
    successToast("محصول با موفقیت به سبد سفارش اضافه شد.");
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>اجاره {items.name}</title>
      </Helmet>
      {/* Breadcrumb Start */}
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li>
                <Link to="/products">محصولات</Link>
              </li>
              <li className="active">
                <a>{items.name}</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* Product Thumbnail Start */}
      <div className="main-product-thumbnail pb-60">
        <div className="container">
          <h1 style={{ fontFamily: "BYekan" }} className="text-center mb-20">  اجاره {items.name}</h1>
          <div className="row">
            <div className="col-lg-5">
              <ImageWithThmbView name={items.name} images={items.images} />
            </div>
            <div className="col-lg-7 ">
              <OrderSection
                items={items}
                states={{
                  isDaily,
                  setIsDaily,
                  orderDaysOrHours,
                  setOrderDaysOrHours,
                  deliveryReq,
                  setDeliveryReq,
                  _addToBasket,
                  total,
                }}
              />
              {items.specs ? (
                <SpecTable specs={items.specs} name={items.name} />
              ) : (
                <p style={{ fontFamily: "BYekan", textAlign: "justify", marginTop: 10 }}>{items.details}</p>
              )}
            </div>
          </div>
          {/* Row End */}
        </div>
        {/* Container End */}
      </div>
      {/* Product Thumbnail End */}
      {/* Product Thumbnail Description Start */}
      <div className="thumnail-desc pb-60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ul className="main-thumb-desc nav">
                <li>
                  <a className="active" data-toggle="tab" href="#dtail">
                    جزییات
                  </a>
                </li>
              </ul>
              {/* Product Thumbnail Tab Content Start */}
              <div className="tab-content thumb-content border-default text-right">
                <div id="dtail" className="tab-pane in active">
                  <p style={{ fontFamily: "BYekan", textAlign: "justify" }}>
                    {items.details}
                  </p>
                </div>
              </div>
              {/* Product Thumbnail Tab Content End */}
            </div>
          </div>
          {/* Row End */}
        </div>
        {/* Container End */}
      </div>
      {/* Product Thumbnail Description End */}
    </div>
  );
}
