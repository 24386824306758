import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SingleProductGrid from "../Components/Products/SingleProductGrid";
import SingleProductList from "../Components/Products/SingleProductList";
import { ProductsData } from "../Data/ProductsData";
import { useSelector } from "react-redux";

export default function Products(props) {
  const [catetgorySelected, setCategorySelected] = useState(
    props.location.state ? props.location.state : "electrical_tools"
  );
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [pageSelected, setPageSelected] = useState(0);
  const [perInPage, setPerInpage] = useState(8);
  const [QtyItems, setQtyItems] = useState({
    ladders: 0,
    electrical_tools: 0,
    elevators: 0,
  });
  const [pageQty, setPageQty] = useState(0);

  useEffect(() => {
    const filteringData = ProductsData.filter((items) => {
      return catetgorySelected === items.category;
    });
    setPageQty(Math.ceil(filteringData.length / perInPage));
    setFilteredProductData(
      filteringData.lenght >= perInPage
        ? filteringData
        : filteringData.splice(
          pageSelected === 0 ? 0 : pageSelected * perInPage,
          perInPage
        )
    );
    _getQtyItems();
  }, [catetgorySelected, perInPage, pageSelected]);

  const _pageSelectHandle = (category) => {
    setCategorySelected(category);
    setPageSelected(0);
  }

  const _getQtyItems = () => {
    const laddersQty = ProductsData.filter((items) => {
      return items.category === "ladders";
    });
    const electrical_toolsQty = ProductsData.filter((items) => {
      return items.category === "electrical_tools";
    });
    const elevatorsQty = ProductsData.filter((items) => {
      return items.category === "elevators";
    });
    setQtyItems({
      ladders: laddersQty.length,
      electrical_tools: electrical_toolsQty.length,
      elevators: elevatorsQty.length,
    });
    console.log(QtyItems, "Qty");
  };

  const _renderPagination = () => {
    let menuItems = [];
    for (var i = 0; i < pageQty; i++) {
      menuItems.push(i + 1);
    }
    return { menuItems };
  };
  return (
    <div>
      {/* Breadcrumb Start */}
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="active">
                <a>محصولات</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* Shop Page Start */}
      <div className="main-shop-page pb-60">
        <div className="container">
          {/* Row End */}
          <h1 style={{ fontFamily: "BYekan" }} className="text-center mb-10">  {catetgorySelected === "ladders" ? "انواع نردبان" : catetgorySelected === "electrical_tools" ? "انواع ابزارهای برقی" : "انواع بالابر"}</h1>
          <div className="row text-right">
            {/* Sidebar Shopping Option Start */}
            {/* <div className="col-lg-11 ">
              <div className="sidebar white-bg"></div>
            </div> */}
            {/* Sidebar Shopping Option End */}
            {/* Product Categorie List Start */}
            <div className="col-lg-12 order-lg-2">
              {/* Grid & List View Start */}
              <div className="grid-list-top border-default  mb-30 ">
                <div
                  className="btn-group mb-10"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    style={{
                      backgroundColor:
                        catetgorySelected === "ladders" ? "#f1ac06" : "",
                    }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      _pageSelectHandle("ladders")
                    }}
                  >
                    نردبان‌ها({QtyItems.ladders})
                  </button>
                  <button
                    style={{
                      backgroundColor:
                        catetgorySelected === "electrical_tools"
                          ? "#f1ac06"
                          : "",
                    }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      _pageSelectHandle("electrical_tools")
                    }}
                  >
                    ابزارهای برقی({QtyItems.electrical_tools})
                  </button>
                  <button
                    style={{
                      backgroundColor:
                        catetgorySelected === "elevators" ? "#f1ac06" : "",
                    }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      _pageSelectHandle("elevators")
                    }}
                  >
                    بالابرها({QtyItems.elevators})
                  </button>
                </div>
                <div className="grid-list-view f-left col-lg-1 col-sm-12">
                  <ul className="list-inline nav">
                    <li>
                      <a data-toggle="tab" href="#grid-view">
                        <i className="fa fa-th" />
                      </a>
                    </li>
                    <li>
                      <a className="active" data-toggle="tab" href="#list-view">
                        <i className="fa fa-list-ul" />
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
              {/* Grid & List View End */}
              <div className="main-categorie">
                {/* Grid & List Main Area End */}
                <div className="tab-content fix">
                  <div id="grid-view" className="tab-pane ">
                    {filteredProductData.map((items) => (
                      <SingleProductGrid items={items} />
                    ))}
                  </div>
                  {/* #grid view End */}
                  <div id="list-view" className="tab-pane active">
                    {filteredProductData.map((items) => (
                      <SingleProductList items={items} />
                    ))}
                  </div>
                </div>
                {/* Grid & List Main Area End */}
              </div>
              {/*Breadcrumb and Page Show Start */}
              <div className="pagination-box fix">
                <ul className="blog-pagination ">
                  {_renderPagination().menuItems.map((item) => {
                    return (
                      <li>
                        <a
                          onClick={() => {
                            setPageSelected(item - 1);
                          }}
                          href="#"
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/*Breadcrumb and Page Show End */}
            </div>
            {/* product Categorie List End */}
          </div>
          {/* Row End */}
        </div>
        {/* Container End */}
      </div>
      {/* Shop Page End */}
    </div>
  );
}
