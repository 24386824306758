import React, { Fragment } from "react";
import Header from "../Components/Common/Header";
import GreetingModal from "../Components/Modals/GreetingModal";
import Footer from "./../Components/Common/Footer";
export default function MainLayout(props) {
  return (
    <Fragment>
      <Header />
      {props.children}
      <Footer />
    </Fragment>
  );
}
