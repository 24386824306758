import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actionSetUser } from "../redux/actions/user_actions";
import store from "../redux/Store";
import { toast } from "react-toastify";
import { numberWithCommas } from "../Utils";
import { errorToast, successToast, warningToast } from "../Utils/TostiUtil";
import { validPhoneNumber } from "../Utils/validate";

export default function Register() {
  // const user = useSelector((state) => state.userReducer.user);
  const basket = useSelector((state) => state.shopBasketReducer.basket);
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [family, setFamily] = useState("");
  const [sum, setSum] = useState(0);
  const [stringBasket, setStringBasket] = useState("");
  console.log(basket, "ddd");
  useEffect(() => {
    basket && _sumPrice();
    _stringToSend();
  }, [basket, name, mobile, family]);

  const _sumPrice = () => {
    var total = 0;
    for (var i = 0; i < basket.length; i++) {
      total = total + basket[i].price;
    }
    setSum(total);
  };

  const _stringToSend = () => {
    var string = "";
    for (var i = 0; i < basket.length; i++) {
      string =
        string +
        ` ${i + 1})  ${basket[i].productName} ${basket[i].orderDaysOrHours} ${
          basket[i].isDaily ? "روز" : 'ساعت"'
        } ${basket[i].deliveryReq === true ? "با حمل" : "بدون حمل"}\n`;
    }
    setStringBasket(
      `${string} \n قابل پرداخت: ${sum} \n ${name} \n ${family} \n ${mobile}`
    );
  };

  const _submitOrder = async () => {
    if (name.length === 0 || family.length === 0 || mobile.length === 0) {
      errorToast("لطفا مشخصات خود را کامل وارد نمایید.");
    } else {
      if (validPhoneNumber(mobile)) {
        store.dispatch(
          actionSetUser({
            name: name,
            family: family,
            mobile: mobile,
          })
        );
        _sumPrice();
        _stringToSend();
        successToast("همکاران پشتیبانی بزودی با شما تماس میگیرند.");
        await fetch(
          `https://webone-sms.ir/SMSInOutBox/SendSms?username=org.shekarchyan&password=caps8643&from=5000284049&to=09024786831&text=${stringBasket}`,
          {
            method: "GET",
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then((res) => {
          console.log(res);
        });
      } else {
        errorToast("شماره همراه صحیح نمی‌باشد.");
      }
    }
  };

  return (
    <div>
      {/* Breadcrumb Start */}
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="active">
                <a>ثبت نام</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* Register Account Start */}
      <div style={{ direction: "rtl" }} className="register-account pb-60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="register-title">
                <h4
                  style={{ fontFamily: "BYekan", textAlign: "center" }}
                  className="mb-10"
                >
                  مبلغ قابل پرداخت: {numberWithCommas(sum)}
                </h4>
                <h3
                  style={{ fontFamily: "BYekan", textAlign: "right" }}
                  className="mb-10"
                >
                  ورود اطلاعات کاربر
                </h3>
              </div>
            </div>
          </div>
          {/* Row End */}
          <div className="row">
            <div className="col-sm-12">
              <form
                onSubmit={(event) => event.preventDefault()}
                className="form-horizontal"
                action="#"
              >
                <fieldset>
                  <legend style={{ fontFamily: "BYekan", textAlign: "right" }}>
                    فرم اطلاعات شخصی
                  </legend>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="f-name"
                        placeholder="نام"
                        onChange={(p) => {
                          setName(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="l-name"
                        placeholder="نام خانوادگی"
                        onChange={(p) => {
                          setFamily(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div style={{ marginTop: 10 }} className="col-sm-10">
                      <input
                        type="mobile"
                        className="form-control"
                        id="number"
                        placeholder="شماره همراه"
                        onChange={(p) => {
                          setMobile(p.target.value);
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
                <div className="newsletter-btn">
                  <a
                    onClick={() => {
                      _submitOrder();
                    }}
                  >
                    پرداخت
                  </a>
                </div>
              </form>
            </div>
          </div>
          {/* Row End */}
        </div>
        {/* Container End */}
      </div>
      {/* Register Account End */}
    </div>
  );
}
