import React from 'react'
import { Link, NavLink } from 'react-router-dom';

export default function SingleProductGrid(props) {
    const { items } = props
    return (
        <div>
            {/* Single Product Start */}
            <div className="colum">
                <div className="col-lg-3 col-sm-6">
                    <div className="single-product">
                        {/* Product Image Start */}
                        <div className="pro-img">
                            <NavLink to={{
                                pathname: '/product',
                                state: { items }
                            }}>
                                <img className="primary-img" src={items.images[0].source} alt={items.name} />
                                <img className="secondary-img" src={items.images[0].source} alt={items.name} />
                            </NavLink>
                        </div>
                        {/* Product Image End */}
                        {/* Product Content Start */}
                        <div className="pro-content">
                            {/* <div className="product-rating">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                            </div> */}
                            <h1 className="mb-10" style={{ fontFamily: "BYekan" }}>
                                <NavLink
                                    to={{
                                        pathname: "/product",
                                        state: { items },
                                    }}
                                    style={{ fontFamily: "BYekan" }}
                                >
                                    اجاره {items.name}
                                </NavLink>
                            </h1>
                            <p><span style={{ fontFamily: "BYekan" }} className="price">{`اجاره روزانه ${items.price.daily} تومان`}</span></p>
                            <div className="pro-actions">
                                <div className="actions-secondary">
                                    {/* <a href="wishlist.html" data-toggle="tooltip" title="Add to Wishlist"><i className="fa fa-heart" /></a> */}

                                    <NavLink className="add-cart" to={{
                                        pathname: '/product',
                                        state: { items }
                                    }} data-toggle="tooltip" title="Product details">جزییات محصول</NavLink>
                                    {/* <a href="compare.html" data-toggle="tooltip" title="Add to Compare"><i className="fa fa-signal" /></a> */}
                                </div>
                            </div>
                        </div>
                        {/* Product Content End */}
                        {/* <span className="sticker-new">-30%</span> */}
                    </div>
                </div>
                {/* Single Product End */}
            </div>
        </div>
    )
}
