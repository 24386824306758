import React from "react";
import Gallery from "react-grid-gallery";

const IMAGES = [
  {
    src: "img/customerImg/1.jpg",
    thumbnail: "img/customerImg/1.jpg",
    caption: "اجاره نردبان بلند",
  },
  {
    src: "img/customerImg/2.jpg",
    thumbnail: "img/customerImg/2.jpg",
    caption: "اجاره نردبان مخابراتی",
  },
  {
    src: "img/customerImg/3.jpg",
    thumbnail: "img/customerImg/3.jpg",
    caption: "اجاره نردبان کشویی",
  },
  {
    src: "img/customerImg/4.jpg",
    thumbnail: "img/customerImg/4.jpg",
    caption: "اجاره نردبان هشتی",
  },
  {
    src: "img/customerImg/5.jpg",
    thumbnail: "img/customerImg/5.jpg",
    caption: "اجاره نردبان",
  },
];
export default function CustomerGallery() {
  return (
    <div className="container mb-10">
      <div
        style={{
          padding: "10px",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          alignItems: "center",
        }}
        className="text-right border"
      >
        <h4 style={{ fontFamily: "BYekan" }}>گالری مشتریان</h4>
      </div>
      <div
        style={{
          display: "block",
          minHeight: "1px",
          width: "90%",
          overflow: "auto",
          marginBottom: 20,
        }}
      >
        <Gallery
          enableImageSelection={false}
          rowHeight={250}
          margin={40}
          images={IMAGES}
        />
      </div>
    </div>
  );
}
