import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Basket from "./Header/Basket";
import GreetingModal from './../Modals/GreetingModal';

export default function Header() {
  const user = useSelector((state) => state.userReducer.user);
  const basket = useSelector((state) => state.shopBasketReducer.basket);
  const params = useLocation()
  const phoneNumber = localStorage.getItem("phoneNumber")
  return (
    <div className="wrapper homepage">
      <div className="container">

        <header>
          {
            params.pathname === "/" && !phoneNumber && <GreetingModal />
          }
          {/* Header Bottom Start */}
          <div className="header-bottom header-sticky">
            <div className="container">
              <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="row"
              >
                {" "}
                <nav
                  style={{ flexDirection: "rtl" }}
                  className="navbar navbar-expand-sm navbar"
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fa fa-bars" />
                    </span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item active">
                        <Link className="nav-link" to="/">
                          خانه <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/products">
                          محصولات
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/aboutus">
                          درباره ما
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contactus">
                          تماس با ما
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <li className="nav-item">
                    <Link to="/register">
                      {user.name
                        ? `${user.name} ${user.family} به اپزار خوش آمدید`
                        : "ورود"}
                    </Link>
                  </li> */}
                  {basket.length > 0 && <Basket />}
                </nav>
                <div className="col-lg-6 col-sm-4 ">
                  <img
                    style={{ width: "50%" }}
                    src="img/logo/logo.png"
                    alt="logo-image"
                  />
                </div>
                {/* Primary Vertical-Menu End */}
                {/* Search Box Start */}
                {/* <div className="col-lg-4 col-sm-4 ml-auto mr-auto">
                  <div className="search-box-view">
                    <form action="#">
                      <input style={{ textAlign: 'right' }} type="text" className="email" placeholder="جستجوی محصول" name="product" />
                      <button type="submit" className="submit" />
                    </form>
                  </div>
                </div> */}
                {/* Search Box End */}
              </div>
              {/* Row End */}
            </div>
            {/* Container End */}
          </div>
          {/* Header Bottom End */}
        </header>
      </div>
    </div>
  );
}
