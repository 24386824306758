import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div>
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="active">
                <a>درباره ما</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* About Main Area Start */}
      <div className="about-main-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="about-content text-right">
                <h1 style={{ fontFamily: "BYekan" }}>درباره اپزار</h1>
                <p style={{ fontFamily: "BYekan", textAlign: 'justify', marginTop: 10 }}>
                  سه سال پس از شروع به کار پلکان که سامانه خدمات اجاره ی نردبان
                  های بلند و صنعتی است، اپزار متولد شد. بواسطه بالا بودن قیمت
                  ابزارهای صنعتی از یک سو و عدم نیاز مستمر به استفاده ی ابزار،
                  وجود کسب و کار اجاره ابزار را بر همگان روشن می کند. از دیگر سو
                  مشتریان پلکان بارها متقاضی ابزارهای دیگر برای انجام کار خود
                  بودند که پلکان از ارائه آن خدمت محروم بوده است. با این تاریخچه
                  و از ابتدای سال 1400 اپزار کار خود را آغاز نموده است. به
                  پشتوانه ی تجارب پلکان، اپزار به روشهای متنوع زمانی و با شبکه
                  گسترده تحویل، ابزار را در محل مشتری تحویل می دهد. برای سفارش
                  اپزار از طریق وب سایت اقدام نمایید یا با شماره تلفن
                  09024786831 تماس حاصل نمایید.
                </p>{" "}
                <br />
                <ul className="mt-20 about-content-list">
                  <li>
                    <h3 style={{ fontFamily: "BYekan", textAlign: 'justify', marginTop: 10 }} >حمل و نقل رایگان</h3>
                  </li>
                  <li>
                    <h3 style={{ fontFamily: "BYekan", textAlign: 'justify', marginTop: 10 }} >تنوع خدمات بصورت ساعتی و روزانه</h3>
                  </li>
                  <li>
                    <h3 style={{ fontFamily: "BYekan", textAlign: 'justify', marginTop: 10 }} >تنوع ساعت خدمات قابل ارائه</h3>
                  </li>
                  <li>
                    <h3 style={{ fontFamily: "BYekan", textAlign: 'justify', marginTop: 10 }} >صدور بیمه حادثه</h3>
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ marginTop: 20 }} className="col-lg-7 col-md-12">
              <div className="about-img">
                <img
                  className="img"
                  src="img/aboutUs/aboutUs.png"
                  alt="اجاره انواع ابزار"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Main Area End */}
      {/* Our Mission Start */}
      <div className="about-bottom pt-50 pb-60">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12 text-right">
              <div className="">
                <h2 style={{ fontFamily: "BYekan" }}>خدمات ما</h2>
                <div className="ht-about-work ">
                  <span>1</span>
                  <div className="ht-work-text">
                    <h3 style={{ fontFamily: "BYekan" }}>
                      اجاره نردبان بلند، اجاره بالابر
                    </h3>
                    <h3 style={{ fontFamily: "BYekan" }}>
                      اجاره  ابزار دستی
                    </h3>
                    <h3 style={{ fontFamily: "BYekan" }}>
                      اجاره انواع ابزار صنعتی، اجاره هیلتی، اجاره دریل
                    </h3>
                    <h3 style={{ fontFamily: "BYekan" }}>
                      اجاره فارسی‌بر، اجاره عمود بر و اجاره ابزار نجاری
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6">
              <div className="ht-single-about pb-sm-40 text-right">
                <h2 style={{ fontFamily: "BYekan" }}>تجربیات ما</h2>
                <h3 style={{ fontFamily: "BYekan" }}>
                  اجاره نردبان بلند، اجاره بالابر
                </h3>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Our Mission End */}
    </div>
  );
}
