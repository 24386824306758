import React from 'react'
import { Link } from 'react-router-dom'

export default function Login() {
    return (
        <div>
            {/* Breadcrumb Start */}
            <div className="breadcrumb-area ptb-60 ptb-sm-30">
                <div className="container">
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/">خانه</Link></li>
                            <li className="active"><a >ورود</a></li>
                        </ul>
                    </div>
                </div>
                {/* Container End */}
            </div>
            {/* Breadcrumb End */}
            {/* LogIn Page Start */}
            <div className="log-in pb-60">
                <div className="container">
                    <div className="row">
                        {/* New Customer Start */}
                        <div className="col-sm-6">
                            <div className="well">
                                <div className="new-customer">
                                    <h3>NEW CUSTOMER</h3>
                                    <p className="mtb-10"><strong>Register</strong></p>
                                    <p>By creating an account you will be able to shop faster, be up to date on an order's status, and keep track of the orders you have previously made</p>
                                    <a className="customer-btn" href="register.html">continue</a>
                                </div>
                            </div>
                        </div>
                        {/* New Customer End */}
                        {/* Returning Customer Start */}
                        <div className="col-sm-6">
                            <div className="well">
                                <div className="return-customer">
                                    <h3 className="mb-10">RETURNING CUSTOMER</h3>
                                    <p className="mb-10"><strong>I am a returning customer</strong></p>
                                    <form action="#">
                                        <div className="form-group">
                                            <label className="control-label">Enter your email address here...</label>
                                            <input type="text" name="email" placeholder="Enter your email address here..." id="input-email" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Password</label>
                                            <input type="text" name="pass" placeholder="Password" id="input-password" className="form-control" />
                                        </div>
                                        <p className="lost-password"><a href="forgot-password.html">Forgot password?</a></p>
                                        <input type="submit" defaultValue="Login" className="return-customer-btn" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Returning Customer End */}
                    </div>
                    {/* Row End */}
                </div>
                {/* Container End */}
            </div>
            {/* LogIn Page End */}
        </div>

    )
}
