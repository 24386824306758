import React from "react";
import { Link } from "react-router-dom";

export default function ContactUs() {
  return (
    <div>
      {/* Breadcrumb Start */}
      <div className="breadcrumb-area ptb-60 ptb-sm-30">
        <div className="container">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">خانه</Link>
              </li>
              <li className="active">
                <a>تماس با ما</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Container End */}
      </div>
      {/* Breadcrumb End */}
      {/* Google Map Start */}
      {/* <div className="container">
                <div id="map" />
            </div> */}
      {/* Google Map End */}
      {/* Contact Email Area Start */}
      <div className="contact-email-area ptb-60">
        <div className="container">
          <div className="row text-right">
            <div className="col-sm-12">
              <h1 style={{ fontFamily: "BYekan" }}>تماس با ما</h1>
              <div
                style={{ marginTop: 20 }}
                className="col-lg-6  col-md-6 col-sm-6"
              >
                <div className="single-footer">
                  <div className="footer-content">
                    <div className="loc-address">
                      <span>
                        <i className="fa fa-map-marker" />
                        <h3 style={{ fontFamily: "BYekan" }}>
                          آدرس: تهران، بلوارآفریقــا، پایین تر از پل میرداماد، برج نگین آفریقا (پلاک88)، طبقه اول، واحد 105 - کد پستی: 1969734737
                        </h3>
                      </span>
                      <span>
                        <i className="fa fa-envelope-o" />
                        <a href="mailto:info@apzaar.ir">
                          <h3 style={{ fontFamily: "BYekan" }}>ایمیل: info@apzaar.ir</h3>
                        </a>
                      </span>
                      <span>
                        <i className="fa fa-phone" />
                        <a href="tel:02174582104">
                          <h3 style={{ fontFamily: "BYekan" }}>
                            تلفن پشتیبانی: 02174582104
                          </h3>
                        </a>
                      </span>
                      <span>
                        <i className="fa fa-phone" />
                        <a href="tel:09024786831">
                          <h3 style={{ fontFamily: "BYekan" }}>
                            همراه: 09024786831
                          </h3>
                        </a>
                      </span>
                    </div>
                    {/* <div className="payment-mth"><a href="#"><img className="img" src="img/footer/1.png" alt="payment-image" /></a></div> */}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* Contact Email Area End */}
    </div>
  );
}
